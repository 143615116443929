import React from 'react';
import AccountApplication from 'components/AccountApplication';
import './App.css';
import { BrowserRouter } from 'react-router-dom';

export default function App() {
  return (
    <div>
      <BrowserRouter>
      <AccountApplication />
      </BrowserRouter>
    </div>
  );
}

import { useEffect, useState } from 'react';
import { axiosInstance } from 'apis/axiosConfig';
import { Form, Input, Button, Checkbox, Select, Modal } from 'antd';
import './form.css';
import ReactGA from "react-ga4";
import { useLocation } from 'react-router-dom';

interface TResponse {
  Result: string;
  Message: string;
}

export default function AccountApplication() {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);

  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const [form] = Form.useForm();

  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const phonePattern = /^[0-9]{1,11}$/;

  const formAPI = async (data: any) => {
    try {
      const response = await axiosInstance.post<TResponse>('/form/', data);
      return response;
    } catch (error) {
      Modal.error({
        title: '오류가 발생했습니다.',
        content: '계정 신청 중 오류가 발생했습니다. 다시 시도해주세요.'
      });
    }
  };

  const onFinished = async (data: any) => {
    const { agreement, ...dataWithoutAgreement } = data;
    const response = await formAPI(dataWithoutAgreement);
    if (response) {
      setFormSubmitted(true);
      // 폼 값 초기화
      form.setFieldsValue({
        email: '',
        manager: '',
        company: '',
        remark: '',
        telNumber: '',
        password: '',
        agreement: false,
        recommend: ''

      });
    }
  };

  useEffect(() => {
    if (formSubmitted) {
      Modal.success({
        title: '계정 신청이 접수되었습니다!.',
        content: '담당자 확인하여 계정생성 후 연락드리겠습니다.'
      });
      setFormSubmitted(false);
    }
  }, [formSubmitted]);


  useEffect(() => {
    if (!window.location.href.includes("localhost")) { 
      ReactGA.initialize("G-JPMLYRCBVC");
    }
    setInitialized(true);
  }, []);
  useEffect(() => {
    if (initialized) {
      ReactGA.set({ page: location.pathname });
      ReactGA.send("pageview");
    }
  }, [initialized, location]);



  return (
    <>
      {/* 로고 */}
      <img src="/ANASA_DCS.png" width={267} height={60} alt="ANASA_DCS" style={{ padding: '25px' }} />

      <div style={{ display: 'flex', flexDirection: 'column', margin: '0px auto', width: '60%', minWidth: '300px', maxWidth: '550px' }}>
        {/* 제목 */}
        <h1 style={{ fontSize: '32px', fontWeight: 'bold', lineHeight: '35px', textAlign: 'center', padding: '20px' }}>계정 신청</h1>

        <Form name="account-application" className="account-application-form" form={form} onFinish={onFinished} scrollToFirstError>
          {/* 이메일 */}
          <h2 className="title">
            이메일 <span className="requiredMark">*</span>
          </h2>
          <Form.Item
            name="email"
            rules={[
              { required: true, message: '이메일을 입력하세요.' },
              { pattern: emailPattern, message: '아이디를 이메일 형태로 입력해주세요.' }
            ]}
          >
            <Input id="emailInput" prefix={<span className="material-symbols-outlined">alternate_email</span>} placeholder="이메일을 입력해주세요." />
          </Form.Item>
          {/* 기관 / 회사명 */}
          <h2 className="title">
            기관 / 회사명 <span className="requiredMark">*</span>
          </h2>
          <Form.Item
            name="company"
            rules={[
              {
                required: true,
                message: '기관 / 회사명을 입력해주세요.'
              }
            ]}
          >
            <Input
              id="companyInput"
              prefix={<span className="material-symbols-outlined">apartment</span>}
              type="text"
              placeholder="기관 / 회사명을 입력해주세요."
            />
          </Form.Item>
          {/* 담당자명 */}
          <h2 className="title">
            담당자명 <span className="requiredMark">*</span>
          </h2>
          <Form.Item name="manager" rules={[{ required: true, message: '담당자명을 입력하세요.' }]}>
            <Input id="managerInput" prefix={<span className="material-symbols-outlined">person</span>} placeholder="담당자명을 입력해주세요." />
          </Form.Item>
          {/* 연락처 */}
          <h2 className="title">
            연락처 <span className="requiredMark">*</span>
          </h2>
          <Form.Item
            name="telNumber"
            rules={[
              { required: true, message: '연락처를 입력하세요.' },
              { pattern: phonePattern, message: '연락처 형식에 맞지 않습니다.' }
            ]}
          >
            <Input id="telNumberInput" prefix={<span className="material-symbols-outlined">phone_iphone</span>} placeholder="연락처를 입력해주세요." />
          </Form.Item>
          {/* 제품을 알게 된 계기 */}
          {/* <h2 className="title">
            제품을 알게 된 계기 <span className="requiredMark">*</span>
          </h2>
          <Form.Item name="remark" rules={[{ required: true, message: '제품을 알게 된 계기를 선택해주세요.' }]}>
            <Select placeholder="제품을 알게 된 계기를 선택해주세요.">
              <Select.Option value="searching_google">검색 (구글)</Select.Option>
              <Select.Option value="searching_naver">검색 (네이버)</Select.Option>
              <Select.Option value="searching_etc">검색 (기타)</Select.Option>
              <Select.Option value="introduce">지인소개</Select.Option>
              <Select.Option value="advertisement">광고</Select.Option>
            </Select>
          </Form.Item> */}
          {/* 제품을 알게 된 계기 상세*/}
          <h2 className="title">
          [선택] 제품을 알게 된 경로 (카페, 매거진 등)
          </h2>          
          <Form.Item
            name="remark"
          >
            <Input
              id="remarkDetailInput"
              prefix={<span className="material-symbols-outlined">Help</span>}
              type="text"
              placeholder="방문하신 카페, 사이트 명을 입력해주세요."
            />
          </Form.Item>

          {/* 추천인*/}
          <h2 className="title">
          [선택] 추천인
          </h2>          
          <Form.Item
            name="recommend"
          >
            <Input
              id="recommendInput"
              prefix={<span className="material-symbols-outlined">Recommend</span>}
              type="text"
              placeholder="추천하는 직원 / 업체를 입력해주세요."
            />
          </Form.Item>

          {/* 개인정보 이용 동의 */}
          <Form.Item
            name="agreement"
            valuePropName="checked"
            rules={[
              {
                validator: (_, value) => (value ? Promise.resolve() : Promise.reject(new Error('개인정보 이용 동의를 체크해주세요.')))
              }
            ]}
          >
            <Checkbox>
              <span className="requiredMark">(필수)</span> 개인정보 이용 동의
              <a target='_blank'
                href="https://anasa21-my.sharepoint.com/:b:/g/personal/hojun_anasa_co_kr/Ec55_9GWLtdGt38kx-K_Xo0B-Sz1RT3ogUE-mTw4c8bKaw?e=e16MHs"
                style={{ marginLeft: '10px' }}
              >
                상세보기
              </a>
            </Checkbox>
          </Form.Item>
          <Form.Item style={{ textAlign: 'center' }}>
            <Button
              type="primary"
              htmlType="submit"
              className="submit-btn"
              block
              id="submitBtn"
              style={{
                width: '300px',
                height: '50px',
                backgroundColor: '#5182FF',
                fontSize: '24px',
                color: 'white',
                fontWeight: 'bold',
                borderRadius: '30px',
                boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.2)'
              }}
            >
              신청하기
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
}

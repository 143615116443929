import axios from 'axios';

export const axiosInstance = axios.create({
  baseURL: 'https://form.anasa-app.com',
  method: 'post',
  timeout: 3000,
  headers: {
    'Cache-Control': 'no-cache',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  },
  responseType: 'json'
});
